@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*
    *
    * This all are the rgb values which are used inside the
    * tailwind config file and custom plugins css file
    *
    */

    /*
    * primary color
    */
    --color-accent: 56, 186, 153;

    /*
    * Hover state color of primary color
    */
    --color-accent-hover: 1, 147, 118;

    /*
    * shade of primary color
    */
    --color-accent-300: 153, 217, 204;

    /*
    * shade of primary color
    */
    --color-accent-400: 77, 188, 165;

    /*
    * shade of primary color
    */
    --color-accent-500: 0, 159, 127;

    /*
    * shade of primary color
    */
    --color-accent-600: 0, 143, 114;

    /*
    * shade of primary color
    */
    --color-accent-700: 0, 119, 95;

    /*
    * light color
    */
    --color-light: 255, 255, 255;

    /*
    * dark color
    */
    --color-dark: 0, 0, 0;

    /*
    * Black muted color
    */
    --color-muted-black: 17, 17, 17;

    /*
    *
    * TEXT colors
    *
    */

    /*
    * text color used for body/base text -> gray-500
    */
    --text-base: 107, 114, 128;

    /*
    * dark base text color  -> gray-600
    */
    --text-base-dark: 75, 85, 99;

    /*
    * muted text color -> gray-400
    */
    --text-muted: 156, 163, 175;

    /*
    * light muted text color - gray-300
    */
    --text-muted-light: 209, 213, 219;

    /*
    * text color used for heading text -> gray-700
    */
    --text-sub-heading: 55, 65, 81;

    /*
    * text color used for heading text -> gray-800
    */
    --text-heading: 31, 41, 55;

    /*
    * text color used for heading text -> gray-900
    */
    --text-bolder: 31, 41, 55;

    /*
    *
    * border colors
    *
    */

    /*
    * border color 50
    */
    --color-border-50: 249, 250, 251;

    /*
    * border color 100
    */
    --color-border-100: 243, 244, 246;

    /*
    * border color 200
    */
    --color-border-200: 229, 231, 235;

    /*
    * border base color which is 300
    */
    --color-border-base: 209, 213, 219;

    /*
    * border color 400
    */
    --color-border-400: 156, 163, 175;

    /*
    *
    * Tailwind default gray color override
    *
    */

    /*
    * gray-50
    */
    --color-gray-50: 249, 250, 251;

    /*
    * gray-100
    */
    --color-gray-100: 243, 244, 246;

    /*
    * gray-200
    */
    --color-gray-200: 229, 231, 235;

    /*
    * gray-300
    */
    --color-gray-300: 209, 213, 219;

    /*
    * gray-400
    */
    --color-gray-400: 156, 163, 175;

    /*
    * gray-500
    */
    --color-gray-500: 107, 114, 128;

    /*
    * gray-600
    */
    --color-gray-600: 75, 85, 99;

    /*
    * gray-700
    */
    --color-gray-700: 55, 65, 81;

    /*
    * gray-800
    */
    --color-gray-800: 31, 41, 55;

    /*
    * gray-900
    */
    --color-gray-900: 31, 41, 55;
  }

  a {
    @apply no-underline;
  }
  .picker {
    /* position: relative; */
  }
  .swatch {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 3px solid #fff;
    box-shadow:
      0 0 0 1px rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .popover {
    position: absolute;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    background: #fff;
  }

  .picker__swatches {
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
  }

  .picker__swatch {
    width: 22px;
    height: 22px;
    margin: 4px;
    border: none;
    padding: 0;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
  }
}
@import './custom-plugins.css';
@import './style.css';
