.image-editor-button {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: transparent;
  fill: #88888b;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  color: currentColor;
  padding: 0;
}
.image-editor-button--active {
  background: rgba(255, 255, 255, 0.03);
  fill: currentColor;
}
.image-editor-button:hover,
.image-editor-button:focus {
  background: rgba(255, 255, 255, 0.03);
}
@media (max-width: 540px) {
  .image-editor-button {
    width: 32px;
    height: 32px;
  }
}
