.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.cursor_table tr.rc-table-row td:hover {
  cursor: pointer;
}

.react-datepicker-wrapper .react-datepicker__input-container input:disabled {
  background-color: #f5f5f5; /* Light gray background */
  color: #a9a9a9; /* Gray text color */
  cursor: not-allowed; /* Indicate that the input is not interactive */
  border: 1px solid #dcdcdc; /* Optional: gray border */
  opacity: 0.6; /* Optional: reduce opacity for a disabled look */
}

input:disabled {
  background-color: #f5f5f5; /* Light gray background */
  color: #a9a9a9; /* Gray text color */
  cursor: not-allowed; /* Indicate the input is not interactive */
  border: 1px solid #dcdcdc; /* Optional: gray border */
  opacity: 0.6; /* Optional: reduce opacity for a disabled look */
}
